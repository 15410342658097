import Vue from 'vue'
import Router from 'vue-router'


Vue.use(Router)

// const originalReplace = Router.prototype.push;
// Router.prototype.push = function push(location) {
//     return originalReplace.call(this, location)
// }

export default new Router({
    mode: 'hash',
    linkExactActiveClass: 'active',
    routes: [
        // { 
            // path: '/', name: 'home', meta: { title: '广州有信科技有限公司' }, component: resolve => require(['./components/Wrap.vue'],resolve),
            // children: [
                {path: '/', name: 'home', meta: { title: '广州有信科技有限公司' }, component: resolve => require(['./views/Home.vue'],resolve)},

                {
                    path: '*',
                    component: resolve => require(['./components/NotFound.vue'],resolve),
                },
            // ]
        // }

        
    ],
})
